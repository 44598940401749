import React from "react";

import {
  Container,
  Button,
  Typography,
  TextField,
  Paper,
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
// Customizable Area End

import DuplicatedetectionController, {
  Props,
} from "./DuplicatedetectionController";

export default class Duplicatedetection extends DuplicatedetectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container style={{ width: "500px", marginTop: "10px" }}>
        <Paper style={{ padding: 16 }}>
          <Typography variant="h5" component="h3">
            Duplicate Detection
          </Typography>
          <form noValidate autoComplete="off">
            <TextField
              label="First Name"
              fullWidth
              data-test-id="firstname"
              margin="normal"
              variant="outlined"
              value={this.state.firstName}
              onChange={(event) => {
                this.handleFirstNameChange(event.target.value);
              }}
            />
            <TextField
              label="Last Name"
              fullWidth
              margin="normal"
              data-test-id="lastname"
              value={this.state.lastName}
              variant="outlined"
              onChange={(event) => {
                this.handleLastNameChange(event.target.value);
              }}
            />
            <TextField
              label="Full Phone Number"
              fullWidth
              value={this.state.fullPhoneNumber}
              margin="normal"
              data-test-id="phonenumber"
              variant="outlined"
              onChange={(event) => {
                this.handlePhoneNumberChange(event.target.value);
              }}
            />
            <TextField
              label="Password"
              fullWidth
              margin="normal"
              data-test-id="password"
              value={this.state.password}
              variant="outlined"
              type="password"
              onChange={(event) => {
                this.handlePasswordChange(event.target.value);
              }}
            />
            <TextField
              label="Email"
              fullWidth
              data-test-id="emailaddress"
              margin="normal"
              variant="outlined"
              value={this.state.emailAddress}
              type="email"
              onChange={(event) => {
                this.handleEmailAddressChange(event.target.value);
              }}
            />
            <TextField
              label="User Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={this.state.userName}
              data-test-id="username"
              onChange={(event) => {
                this.handleUserNameChange(event.target.value);
              }}
            />
            <Button
              onClick={() => {
                this.submitAccount();
              }}
              variant="contained"
              color="primary"
              fullWidth
              data-test-id="submit-btn"
              style={{ marginTop: 16 }}
            >
              Submit
            </Button>
            <Typography
              data-test-id="text-message-test-id"
              variant="h6"
              component="h4"
            >
              {this.state.successMessage}
            </Typography>
          </form>
        </Paper>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
