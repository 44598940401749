Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.postApiMethod = "POST";
exports.delApiMethod = "DELETE";
exports.createAccountEndPoint = "/account/accounts";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Duplicatedetection";
exports.labelBodyText = "Duplicatedetection Body";
exports.labelSubmitButtom = "Submit";
exports.labelSubmitButtom = "Submit";
exports.btnExampleTitle = "CLICK ME";
exports.putApiMethod = "PUT";
exports.duplicateDetetionGetAPIEndPoint = '/bx_block_Duplicatedetection/client_details';
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End