import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  emailAddress: string;
  fullPhoneNumber: string;
  password: string;
  userName: string;
  successMessage: string;
  duplicateRecrods: [];
  isCreate: boolean;
  recordId: string;
  duplicateDetectionEndPoint: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DuplicatedetectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCreateAccountApiCallId: string = "";
  apiClientRecordApiCallId: string = "";
  apiClientDuplicateDeleteApiCalId: string = "";
  // duplicateDetectionEndPoint: string = configJSON.duplicateDetetionGetAPIEndPoint;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      fullPhoneNumber: "",
      password: "",
      userName: "",
      successMessage: "",
      duplicateRecrods: [],
      isCreate: true,
      recordId: "",
      duplicateDetectionEndPoint: configJSON.duplicateDetectionEndPoint
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDuplicateDetection();
  }

  getDuplicateDetection = async () => {
    let token = await getStorageData('token');

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };

    const accountApiCallMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiClientRecordApiCallId = accountApiCallMsg.messageId;

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateDetetionGetAPIEndPoint
    );

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    runEngine.sendMessage(accountApiCallMsg.id, accountApiCallMsg);
  }

  submitAccount = async () => {
    let token = await getStorageData('token');

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };

    const accountApiCallMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateAccountApiCallId = accountApiCallMsg.messageId;

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isCreate ? configJSON.duplicateDetetionGetAPIEndPoint : configJSON.duplicateDetetionGetAPIEndPoint + '/' + this.state.recordId
    );

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.isCreate ? configJSON.postApiMethod : configJSON.putApiMethod
    );

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "client_details": {
          "first_name": this.state.firstName,
          "last_name": this.state.lastName,
          "email": this.state.emailAddress
        }
      }
      ),
    );
    runEngine.sendMessage(accountApiCallMsg.id, accountApiCallMsg);
  }

  deleteDupuplicateRecord = async (recordId: string) => {

    let token = await getStorageData('token');

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };

    const accountApiCallMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiClientDuplicateDeleteApiCalId = accountApiCallMsg.messageId;

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateDetetionGetAPIEndPoint + '/' + recordId
    );

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    accountApiCallMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    runEngine.sendMessage(accountApiCallMsg.id, accountApiCallMsg);
  }

  editDupuplicateRecord = (recordId: string, item: { email: string, first_name: string, "is_duplicate": boolean, "last_name": string }) => {
    this.setState({ recordId: recordId, isCreate: false, firstName: item.first_name, lastName: item.last_name, emailAddress: item.email });
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiCreateAccountApiCallId) {
        if (responseJson !== null) {
          this.getDuplicateDetection();
          this.setState({ successMessage: "Success", firstName: "", lastName: '', emailAddress: "" });
        }
      }

      if (apiRequestCallId === this.apiClientRecordApiCallId) {
        let records = responseJson['data'];
        this.setState({ successMessage: "Success", duplicateRecrods: records });
      }

      if (apiRequestCallId === this.apiClientDuplicateDeleteApiCalId) {
        this.getDuplicateDetection();
      }
    }
  }

  setFirstName = (value: string) => {
    this.setState({ firstName: value });
  }

  setLastName = (value: string) => {
    this.setState({ lastName: value })
  }

  setUserName = (value: string) => {
    this.setState({ userName: value })
  }

  setEmailAddress = (value: string) => {
    this.setState({ emailAddress: value })
  }

  handleFirstNameChange = (value: string) => {
    this.setState({ firstName: value })
  }

  handleLastNameChange = (value: string) => {
    this.setState({ lastName: value })
  }

  handlePhoneNumberChange = (value: string) => {
    this.setState({ fullPhoneNumber: value })
  }

  handleEmailAddressChange = (value: string) => {
    this.setState({ emailAddress: value })
  }

  handlePasswordChange = (value: string) => {
    this.setState({ password: value })
  }

  handleUserNameChange = (value: string) => {
    this.setState({ userName: value })
  }
  // Customizable Area Start
  // Customizable Area End
}
